import axios from 'axios'
import {Toast} from 'vant'
import {desCode} from "./encryption";
import Router from '../router/index'
import Store from "../store";

const API = process.env.VUE_APP_API
console.log(API)
// axios 拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  const phone = localStorage.getItem('cellPhone')
  const mobile = JSON.parse(phone);
  if (!config.data) config.data = {}
  if (mobile) config.data.phone = mobile
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 统一封装
const Fetch = async options => {
  let fetch
  //加密
  // options.data=desCode.ncrypted(JSON.stringify(options.data))
  const {url, data, method} = options
  const baseUrl = `${API}/${url}`
  console.log(baseUrl, '请求地址')
  switch (method.toLowerCase()) {
    case 'get':
      fetch = axios.get(baseUrl, {
        params: data,
        withCredentials: false
      })
      break
    case 'post':
      fetch = axios.post(baseUrl, data, {
        transformRequest: [
          function (data) {
            data = JSON.stringify(data)
            return data
          }
        ],
        // 表示跨域请求时是否需要使用凭证 默认false
        withCredentials: false,
        // 设置header
        headers: {
          Accept: 'application/json;charset=UTF-8',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })

      break
    default:
      fetch = axios({...options})
      break
  }
  return fetch
}

// 对返回结果和错误信息进行处理
const request = function (options) {
  return Fetch(options).then(res => {
    if (res.status === 200 && res.data.success) {
      const {code, data} = res.data
      if (res.data.success && code === '200' && data !== null) {
        const result = JSON.parse(desCode.decrypted(data));
        res.data.data = result
        console.log(res.data, '请求结果')
        return Promise.resolve({...result, ...res.data})
      } else {
        return Promise.resolve({...data, ...res.data})
      }
    } else {
      const {result, message} = res.data
      console.log('这里')
      Toast({message})
      if(message==='请刷新页面'){
        window.localStorage.clear();
        Store.dispatch('route/SetNavBar', false)
        Store.dispatch('route/SetTabBar', false)
        Router.replace('/login')
      }
      // throw new Error({ ...result })
      // return Promise.reject(res.data)
      return res.data
    }
  }).catch(error => {
    console.log(error, ' 进入了catch 函数3')
    const {response} = error
    let msg
    let statusCode
    if (response && response instanceof Object) {
      const {
        data,
        statusText
      } = response
      statusCode = response.status
      msg = data.message || statusText
    } else {
      statusCode = 600
      msg = error.message || '服务器开小差了...'
      if (error.message === 'Network Error') {
        msg = '服务器开小差了'
      }
    }

    Toast({
      message: msg
    })

    /* eslint-disable */
    return Promise.reject({
      success: false,
      statusCode,
      message: msg
    })
  })
}

export default {
  // get方法封装
  get: function (url, data) {
    return request({
      url,
      data: data ? data : '',
      method: 'GET'
    })
  },
  // post方法封装
  post: function (url, data) {
    return request({
      url,
      data: data ? data : '',
      method: 'POST'
    })
  }
}

<template>
  <!-- 导航栏-->
  <div class="layout">
    <!--  内容content-->
    <template v-if="needRefresh">
      <van-pull-refresh :class="[ showTabBar?'contain-short':'', 'contain']"
                        v-model="isLoading"
                        success-text="刷新成功"
                        @refresh="onRefresh">
        <router-view :key="activeDate"></router-view>
      </van-pull-refresh>
    </template>
    <template v-else>
      <div :class="[showTabBar?'contain-short':'', 'contain']">
        <router-view :key="activeDate"></router-view>
      </div>
    </template>

    <!--todo  悬浮菜单 -->
    <template v-if="false">
      <van-popover v-model:show="showPopover">
        <van-grid
          square
          clickable
          :border="false"
          column-num="3"
          style="width: 240px;"
        >
          <van-grid-item
            v-for="i in 6"
            :key="i"
            text="选项"
            icon="photo-o"
            @click="showPopover = false"
          />
        </van-grid>
        <template #reference>
          <van-sticky :offset-bottom="60" position="bottom">
            <van-button @click="handleMenu" round type="primary">菜</van-button>
          </van-sticky>
        </template>
      </van-popover>
    </template>


    <!--  Tab栏-->
    <van-tabbar v-if="showTabBar" route>
      <van-tabbar-item replace to="/index" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/bill" icon="search">账单</van-tabbar-item>
      <van-tabbar-item replace to="/mine" icon="setting-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import {toRefs, computed, reactive} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {useStore} from 'vuex'
import {Popover} from 'vant'

export default {
  name: "layout",
  setup() {

    const state = reactive({
      needRefresh: false,
      showTabBar: false,
      isLoading: false,
      activeDate: new Date(),
      title: '飞逸美选',
      showPopover: true
    })
    const Router = useRouter()
    const Store = useStore();
    state.title = computed(() => Store.getters.title)
    state.showTabBar = computed(() => Store.getters.tabBar)
    state.needRefresh = computed(() => Store.getters.refresh)


    // 左上角返回
    const onClickLeft = () => {
      Router.back();
    }

    // 刷新
    const onRefresh = () => {
      state.activeDate = new Date()
      state.isLoading = false
    }

    // 悬浮菜单
    const handleMenu = () => {
      state.showPopover = true;
    }

    return {
      ...toRefs(state),
      onRefresh,
      onClickLeft,
      handleMenu
    }
  }
}

</script>
<style lang="less" scoped>
.layout {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .contain {
    width: 100%;
    height: 100%;
    overflow-y: auto !important;
  }

  .contain-short {
    height: calc(100% - 100px) !important;
    overflow-y: auto !important;
  }

  :deep(.content) {
    height: 100%;
    overflow: auto !important;
  }

  :deep(.van-sticky) {
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>


/**
 *
 * @type {{route: string}}
 */
const state = {
  route: null,
  title: '飞逸美选',
  showNavBar: false,
  showTabBar: false,
  refresh: false,
}

const getters = {
  route: (state) => state.route,
  title: (state) => state.title,
  refresh: (state) => state.refresh,
  showNavBar: (state) => state.showNavBar,
  showTabBar: (state) => state.showTabBar,
}

const mutations = {
  setTitle(state, data) {
    state.title = data
  },
  setRoute(state, data) {
    state.route = data
  },
  setNavBar(state, data) {
    state.showNavBar = data
  },
  setRefresh(state, data) {
    state.refresh = data
  },
  setTabBar(state, data) {
    state.showTabBar = data
  }
}

const actions = {
  SetTitle({commit}, route) {
    commit('setTitle', route)
  },
  SetRoute({commit}, route) {
    commit('setRoute', route)
  },
  SetRefresh({commit}, data) {
    commit('setRefresh', data)
  },
  SetNavBar({commit}, data) {
    commit('setNavBar', data)
  },
  SetTabBar({commit}, data) {
    commit('setTabBar', data)
  }
}


export default {
  state,
  getters,
  mutations,
  actions
}
